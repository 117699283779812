import React, { useEffect, useState } from "react"
import "../assets/fonts/Poppins/Poppins-Light.ttf"
import "../assets/fonts/Poppins/Poppins-Bold.ttf"
import "../assets/fonts/Poppins/Poppins-Regular.ttf"
import "../components/layout.css"
import styled from "styled-components"
import colors from "../colors"
import { Row } from "../components/atomics"
import { getQueryParam } from "../utils/query"
import { URL_EMAIL, URL_INSTA_FLYNOW, URL_WHATSAPP } from "../constants"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2% 8%;


  .icon{
    font-size: 2rem;
		color: ${colors.primary2};
		cursor: pointer;
		transition: all 0.4s linear;
  }
`

const Button = styled.a`
  text-align: center;
  padding: 15px 30px;
  color: white;
  background: ${colors.primary2};
  border-radius: 40px;
  font-family: 'Poppins-Bold';
  cursor: pointer;
  transition: all 0.4s linear;
  &:hover {
    opacity: 0.9;
  }
  text-decoration: none;
  width: 80%;
  align-self: center;
`

const GuiaDefinitivoParaProdutividade = () => {
  const [utm, setUtm] = useState("")
  const [email, setEmail] = useState("")

  const data = {
    id: "combo_app_ebook",
    title: "PREMIUM VITALÍCIO + EBOOK PRODUTIVIDADE",
    oldPrice: "234.69",
    price: "198,69",
    discontPercentage: "20",
    URL_CHECKOUT: "https://buy.stripe.com/5kA5m715T3hPdZC9AO?locale=pt-BR&prefilled_promo_code=FLYN20&utm_source="+utm+"&prefilled_email="+email,
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVO (PLANO PREMIUM VITALÍCIO)",
        data: [
          {
            url: "/apps/productivity",
            title: "Flynow - Produtividade",
            text:
              "Gerencie suas tarefas, crie bons hábitos e conquiste suas metas. Tudo o que você precisa para ser mais produtivo em apenas 1 app!",
            img: require("../assets/images/logo.png"),
            price: "179,99",
          },
        ],
      },
      {
        section: "EBOOK",
        data: [
          {
            url: "/guia-definitivo-para-a-produtividade",
            title: "Guia definitivo para a produtividade",
            text:
              "23 técnicas (+6 BÔNUS) para ser mais produtivo e alcançar seus objetivos!",
            img: require("../assets/images/ebookProdutividade.png"),
            price: "54,70",
          },
        ],
      },
    ],
  }

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  useEffect(() => {
    sendEvent("view_" + data.id)
    setUtm(getQueryParam("utm_source"))
    setEmail(getQueryParam("email"))
  }, [])

  const onCheckout = () => {
    sendEvent("click_checkout_" + data.id)
  }

  return (
    <Container>
      <h1
        style={{ textAlign: "center", fontWeight: "black", display: "inline" }}
      >
        <h1 style={{ color: colors.primary2, display: "inline" }}>COMBO:</h1>{" "}
        {data.title}
      </h1>
      <h4
            style={{
              background: colors.red,
              color: "#fff",
              textAlign: "center",
              margin: 5,
              padding: 5,
            }}
          >
              {data.discontPercentage}% OFF
          </h4>
      {data.sections.map(item => (
        <>
          <br />
          <br />
          <h3 style={{ color: colors.primary2 }}>{item.section}</h3>
          {item.data.map(i => (
            <Row style={{ marginTop: 20 }}>
              <img src={i.img} style={{ height: 100, objectFit: "contain" }} />
              <div style={{ marginLeft: 10 }}>
                <h6 style={{ textTransform: "uppercase" }}>{i.title}</h6>
                <h5
                  style={{
                    fontWeight: "normal",
                    marginBottom: 4,
                    marginTop: 3,
                  }}
                >
                  {i.text}
                </h5>
                <h4 style={{ color: colors.primary2 }}>{i.price}</h4>
              </div>
            </Row>
          ))}
        </>
      ))}

      <div
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          alignSelf: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#333",
        }}
      >
        <h3>
          <span style={{ fontSize: ".8rem" }}>DE</span>
          <s> R$ {data.oldPrice}</s>
        </h3>
        <>
          <h2 style={{ marginTop: 8, marginBottom: 15 }}>
            <span style={{ fontSize: ".8rem" }}>POR APENAS </span>
            <span style={{ color: colors.primary2 }}>R$ {data.price}</span>
          </h2>
          <h4
            style={{
              background: colors.red,
              color: "#fff",
              textAlign: "center",
              margin: 5,
              padding: 5,
            }}
          >
             {data.discontPercentage}% OFF
          </h4>
        </>
      </div>
      <Button href={data.URL_CHECKOUT} onClick={onCheckout}>
        {data.cta}
      </Button>

      <br />
      <br />
      <h3 style={{textAlign: 'center'}}>Alguma dúvida?</h3>
      <br />
      <span style={{textAlign: 'center'}}>Se tiver qualquer dúvida entre em contato conosco!</span>
      {[
        {
          link: URL_WHATSAPP,
          img: <FaWhatsapp className="icon" />,
          description: "31 999107753",
        },
        {
          link: URL_EMAIL,
          img: <MdMailOutline className="icon" />,
          description: "support@appflynow.com",
        },
        {
          link: URL_INSTA_FLYNOW,
          img: <FaInstagram className="icon" />,
          description: "@appflynow",
        },
      ].map(i => (
          <a
            href={i.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ alignSelf: 'center' }}
          >
          <Row style={{marginTop: 10, alignItems: 'center'}}>
            {i.img}
            <h5 style={{ color: colors.primary2 }}>{i.description}</h5>
          </Row>
          </a>
      ))}
    </Container>
  )
}
export default GuiaDefinitivoParaProdutividade
